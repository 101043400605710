import { getBuildEnvVar, getRuntimeEnvVar } from '@wizbii/angular-utilities';

const version = require('../../package.json').version; // eslint-disable-line

const platform = getRuntimeEnvVar('PLATFORM');
const apiDomain = getRuntimeEnvVar('API_DOMAIN');
const wizbiiDomain = getRuntimeEnvVar('WIZBII_DOMAIN');
const wizbiiTeamDomain = getRuntimeEnvVar('WIZBII_TEAM_ENV_FQDN');
const bugsnagApiKey = getRuntimeEnvVar('BUGSNAG_API_KEY');
const deployAssetsUrl = getBuildEnvVar('DEPLOY_ASSETS_URL');
const production = platform !== 'local';

export const environment = {
  applicationId: 'crm-front',
  platform,
  production,
  domain: wizbiiDomain,
  appUrl: window.location.host,
  cookieDomain: window.location.hostname === 'localhost' ? 'localhost' : wizbiiTeamDomain,
  api: {
    authentication: `https://auth.${apiDomain}`,
    sso: `https://sso.internal.${wizbiiDomain}`,
  },
  algolia: {
    clientName: '',
    index: {},
  },
  version,
  bugsnag: {
    apiKey: bugsnagApiKey,
  },
  deployUrl: window.location.hostname === 'localhost' ? `http://${window.location.host}/assets` : deployAssetsUrl,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
if (!environment.production) {
  import('zone.js/dist/zone-error' as any); // Included with Angular CLI.
}
