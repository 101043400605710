import { FeaturesRoutingEnum } from './features-routing.enum';
import { Route } from '@angular/router';
import { loggedGuard } from '@core/guards/logged.guard';

const GUARDS = [loggedGuard];

export const routes: Route[] = [
  {
    path: '',
    canActivate: GUARDS,
    children: [
      {
        path: FeaturesRoutingEnum.Campaigns,
        loadComponent: () => import('./campaigns/campaigns.component').then((module) => module.CampaignsComponent),
      },
      {
        path: FeaturesRoutingEnum.Templates,
        loadComponent: () => import('./templates/templates.component').then((module) => module.TemplatesComponent),
      },
      {
        path: FeaturesRoutingEnum.UserList,
        loadComponent: () => import('./user-list/user-list.component').then((module) => module.UserListComponent),
      },
      {
        path: FeaturesRoutingEnum.FileUpload,
        loadComponent: () => import('./file-upload/file-upload.component').then((module) => module.FileUploadComponent),
      },
      {
        path: FeaturesRoutingEnum.Folder,
        loadComponent: () => import('./folder/folder.component').then((module) => module.FolderComponent),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: FeaturesRoutingEnum.Campaigns,
      },
    ],
  },
];
