import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule, NgZone, inject } from '@angular/core';
import { MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { InterserviceNavbarComponent } from '@commons/interservice-navbar/interservice-navbar.component';
import { bugsnagConfig } from '@config/bugsnag.config';
import { ngxsConfig } from '@config/ngxs.config';
import { CoreComponent } from '@core/core.component';
import { JwtService } from '@core/services/jwt/jwt.service';
import { WizbiiRouterStateSerializer } from '@core/services/serializer/router.serializer';
import { environment } from '@env/environment';
import { FeaturesModule } from '@features/features.module';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { AlgoliaCredentialsService } from '@webservices/algolia/algolia-credentials.service';
import {
  PageUnauthorizedModule,
  WzbBoNavigationSidebarMenuComponent,
  WzbBoNotificationsModule,
  wzbBoNavigationSidebarMenuIconsDirective,
} from '@wizbii-components/bo-angular-ui';
import { ALGOLIA_CREDENTIALS_FETCHERS_TOKEN, Credentials, CredentialsFetchers } from '@wizbii/algolia';
import { bugsnagErrorHandlerFactory } from '@wizbii/angular-bugsnag';
import { JWT_SERVICE_TOKEN, JwtInterceptor } from '@wizbii/jwt';
import { ScrollToModule } from '@wizbii/ngx-scroll-to';
import { JWT_STATE_CONFIG, JwtState } from '@wizbii/stores';
import { AUTHENTICATION_API_CONFIG, AuthenticationWebservice } from '@wizbii/webservices';
import { Observable } from 'rxjs';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot([], {
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
      bindToComponentInputs: true,
    }),
    FeaturesModule,
    NgxsModule.forRoot([JwtState], ngxsConfig),
    NgxsRouterPluginModule.forRoot(),
    WzbBoNotificationsModule.forRoot(),
    MatProgressBarModule,
    wzbBoNavigationSidebarMenuIconsDirective,
    MatDialogModule,
    LoadingBarHttpClientModule,
    InterserviceNavbarComponent,
    LoadingBarRouterModule,
    PageUnauthorizedModule,
    MatIconModule,
    WzbBoNavigationSidebarMenuComponent,
    ScrollToModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  declarations: [CoreComponent],
  bootstrap: [CoreComponent],
  providers: [
    { provide: RouterStateSerializer, useClass: WizbiiRouterStateSerializer },
    {
      provide: JWT_STATE_CONFIG,
      useValue: {
        jwtCookieName: 'wizbii_bo',
        expiryCookieName: 'wizbii_bo_expiry',
        appEnvFqdn: environment.cookieDomain,
      },
    },
    {
      provide: ErrorHandler,
      useFactory: bugsnagErrorHandlerFactory(bugsnagConfig),
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: JWT_SERVICE_TOKEN, useClass: JwtService },
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: MAT_SELECT_CONFIG, useValue: { disableOptionCentering: true } },
    {
      provide: AUTHENTICATION_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.authentication,
      },
    },
    {
      provide: ALGOLIA_CREDENTIALS_FETCHERS_TOKEN,
      useFactory: (algoliaCredentialsService: AlgoliaCredentialsService): CredentialsFetchers => {
        const credentialsObservableFromAccount = algoliaCredentialsService.getCredentialsFromAccount();

        return {
          crm: {
            fetchCredentials(): Observable<Credentials> {
              return credentialsObservableFromAccount;
            },
          },
        };
      },
    },
    AuthenticationWebservice,
  ],
})
export class CoreModule {
  readonly #ngZone = inject(NgZone);
  readonly #matIconRegistry = inject(MatIconRegistry);
  readonly #domSanitizer = inject(DomSanitizer);

  constructor() {
    this.#ngZone.runOutsideAngular(() => {
      this.#matIconRegistry.addSvgIconSet(
        this.#domSanitizer.bypassSecurityTrustResourceUrl(`${environment.deployUrl}/sprites/picto.svg`)
      );
    });
  }
}
