import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-interservice-navbar',
  standalone: true,
  imports: [CommonModule],
  template: `<wiz-sidebar [environment]="env" />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InterserviceNavbarComponent {
  readonly env = environment.platform;
}
