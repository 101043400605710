import { Location, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, PLATFORM_ID, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import { RouterState } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { NavigationItem } from '@wizbii-components/bo-angular-ui';
import { WebVitalsOptions, WebVitalsParams, sendWebVitals } from '@wizbii/utils';
import { filter, map, startWith, take } from 'rxjs';

@Component({
  selector: 'app-core',
  template: `
    <main class="main">
      <aside class="menu">
        <wzb-bo-navigation-sidebar-menu
          *ngIf="currentRoute"
          [items]="navigationItems"
          [reduced]="true"
          [reducible]="false"
          [activeRoute]="currentRoute"
          (itemClicked)="handleNavItemClicked($event)"
        >
          <mat-icon class="menu__logo" [inline]="true" svgIcon="logo" menuLogo />

          <ng-container *ngFor="let icon of icons; trackBy: trackByIcon">
            <mat-icon *wzbBoNavigationSidebarMenuIcon="icon" class="menu__icon" [svgIcon]="icon!" />
          </ng-container>
          <span menuOption></span>
        </wzb-bo-navigation-sidebar-menu>
      </aside>

      <app-interservice-navbar class="interservice-navbar" />
      <div class="content">
        <router-outlet />
      </div>
    </main>
  `,
  styles: [
    `
      .main {
        display: flex;
        min-height: 100vh;
      }

      .content {
        flex: 1;
        padding: 3.125rem 2rem 2rem;
      }

      .menu {
        &__logo {
          width: 3.125rem !important;
          height: 3.125rem !important;
        }

        &__icon {
          color: white;
          width: 1.1875rem;
        }
      }

      /* Warning: keep z-index higher than header in builder-layout component */
      .interservice-navbar {
        position: fixed;
        bottom: 1.25rem;
        left: 2.375rem;
        z-index: 3;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreComponent {
  #store = inject(Store);
  #router = inject(Router);
  #location = inject(Location);
  #platformId = inject(PLATFORM_ID);

  get currentRoute(): string | undefined {
    return this.#currentRouteSig()!;
  }

  #currentRouteSig = toSignal(
    this.#router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => event as NavigationEnd),
      map((event) => event.urlAfterRedirects),
      startWith(this.#location.path()),
      map((url) => url.split('/')[1])
    )
  );

  constructor() {
    this.#webVitals();
  }

  handleNavItemClicked(item: NavigationItem): void {
    this.#router.navigate([item['route']]);
  }

  get icons(): string[] {
    return this.navigationItems.filter((item) => !!item.icon).map((i) => i.icon!);
  }

  readonly navigationItems: NavigationItem[] = [
    {
      route: FeaturesRoutingEnum.Campaigns,
      icon: 'campaigns',
    },
    {
      route: FeaturesRoutingEnum.Templates,
      icon: 'templates',
    },
    {
      route: FeaturesRoutingEnum.UserList,
      icon: 'userList',
    },
    {
      route: FeaturesRoutingEnum.FileUpload,
      icon: 'fileUpload',
    },
    {
      route: FeaturesRoutingEnum.Folder,
      icon: 'folder',
    },
  ];

  trackByIcon(_: number, icon: string): string {
    return icon;
  }

  readonly #webVitals = (): void => {
    this.#store
      .select(RouterState)
      .pipe(
        filter((routerState) => !!routerState?.state),
        take(1)
      )
      .subscribe(({ state: routerState }) => {
        if (routerState.url) {
          const params: WebVitalsParams = {
            params: routerState.params,
            path: routerState.url,
            applicationId: environment.applicationId,
            envFqdn: environment.domain,
          };
          const options: WebVitalsOptions = {
            dev: environment.platform === 'local',
            debug: environment.platform === 'local',
            browser: isPlatformBrowser(this.#platformId),
          };
          sendWebVitals(params, options);
        }
      });
  };
}
